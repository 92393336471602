export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  },

  computed: {
    isUrlAbsolute () {
      if (!this.url) {
        return true
      }
      else if (this.url.startsWith('http')) {
        return true
      }
      return false
    }
  }
}