import { PerspectiveCamera, Scene } from 'three'
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer.js'

export default {
  install (app, options) {
    let dom3d = {}

    dom3d.camera = new PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 1, 10000 )
    dom3d.isSelfCamera = true

    dom3d.scene = new Scene()

    dom3d.renderer = new CSS3DRenderer()
    dom3d.renderer.setSize( window.innerWidth, window.innerHeight )
    
    dom3d.width = window.innerWidth
    dom3d.height = window.innerHeight

    dom3d.attachTo = (element) => {
      if (element === void 0) {
        console.warn('dom3d needs an element to attached to')
        return
      }

      dom3d.container = element
      dom3d.container.appendChild( dom3d.renderer.domElement )
    }

    dom3d.setCamera = (camera) => {
      dom3d.camera = camera
      dom3d.isSelfCamera = false
    }

    dom3d.onResize = (width = window.innerWidth, height = window.innerHeight) => {
      dom3d.width = width
      dom3d.height = height

      if (dom3d.container && dom3d.isSelfCamera) {
        dom3d.renderer.setSize( width, height )
        dom3d.camera.aspect = width / height
        dom3d.camera.updateProjectionMatrix()
      }
      else {
        dom3d.renderer.setSize( width, height )
        dom3d.camera.updateProjectionMatrix()
      }
    }

    dom3d.addObject = (object) => {
      dom3d.scene.add(object)
    }

    dom3d.removeObject = (object) => {
      dom3d.scene.remove(object)
    }

    dom3d.update = () => {
      dom3d.renderer.render( dom3d.scene, dom3d.camera )
    }

    app.config.globalProperties.$dom3d = dom3d
  }
}
