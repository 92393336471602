import { perpendicular } from '@/utils/Maths'
import { visibleViewport } from 'shimmer'
import { Vector3 } from 'three'

// padding from -.5 to .5
export function positionPadding (position, look, dist = 1, padding = .25) {
    // normalize look vector
    look = look.normalize()
    const perp = perpendicular.clockwise({x: look.x, y: look.z})
    const displacement = look.clone().multiplyScalar(dist)

    const screen = visibleViewport(new Vector3(0,0,0), displacement)

    // the * .3 instead of .33 
    position.x = position.x + perp.x * screen.width * padding
    position.z = position.z + perp.y * screen.width * padding

    return {
      look: position,
      position: new Vector3(
        position.x + displacement.x,
        position.y + displacement.y,
        position.z + displacement.z
      )
    }
  }