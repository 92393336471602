<template>
  <component
    :is="to.name ? 'router-link' : href ? 'a' : 'div'"
    :to="to"
    class="button-round u-block"
    :href="href"
    :target="href ? '_blank' : false"
    :class="{'is-small': isSmall, 'is-full': isFull, 'is-ghost': isGhost}"
  >
    <div class="u-box-1by1 u-round u-cursor-pointer" :class="{'u-shadow': !isGhost}">
      <div class="button-round__wrapper u-wrapper-panel u-round">
        <slot class="u-fit-contain" />
      </div>
    </div>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  props: {
    isSmall: {
      type: Boolean,
      default: false
    },
    isFull: {
      type: Boolean,
      default: false
    },
    isGhost: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.button-round
  width 80px
  display inline-block

  +mq($until: 'tablet')
    width 50px
  
  &.is-full
    width 100%
  
  &.is-small
    width 50px

  &.is-small &__wrapper
    padding 24%

  &.is-ghost &__wrapper
    padding 24%
    background-color: $colors.transparent
    border: solid 3px $colors.white
  
  &__wrapper
    padding 27%
    background-color: $colors.white
</style>