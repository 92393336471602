<template>
  <div style="position: fixed; width: 0; height: 0; overflow: hidden; top: -99px">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="arrow-left" viewBox="0 0 10 11">
          <title>Arrow Left</title>
          <path d="M0.513646 6.25682L8.77543 10.3877C9.33804 10.669 10 10.2599 10 9.6309L10 1.36911C10 0.740092 9.33804 0.33098 8.77544 0.612285L0.513646 4.74318C-0.11001 5.05501 -0.11001 5.945 0.513646 6.25682Z"/>
        </symbol>
        <symbol id="arrow-right" viewBox="0 0 18 17">
          <title>Arrow right</title>
          <path d="M1.6242 6.59645H11.78L7.95565 2.77212C7.32172 2.1369 7.32172 1.10937 7.95565 0.475443C8.58957 -0.158481 9.6171 -0.158481 10.2523 0.475443L16.8488 7.07189C17.4827 7.70582 17.4827 8.73465 16.8488 9.36857L10.2523 15.965C9.93406 16.282 9.51967 16.4405 9.10399 16.4405C8.687 16.4405 8.27261 16.282 7.95565 15.965C7.32172 15.3311 7.32172 14.3036 7.95565 13.6683L11.78 9.84402H1.6242C0.727869 9.84402 0.000415802 9.11656 0.000415802 8.22023C0.000415802 7.32391 0.727869 6.59645 1.6242 6.59645Z"/>
        </symbol>
        <symbol id="weird-arrow-right" viewBox="0 0 38 8">
          <title>Weird arrow right</title>
          <path d="M28.2976 0.424316L37.9448 3.86976H28.2976V0.424316Z" fill="white"/>
          <path d="M28.2976 7.31543L37.9448 3.86999H28.2976V7.31543Z" fill="white"/>
          <path d="M0.0449219 3.87012H32.7766" stroke="white" stroke-width="0.689088"/>
        </symbol>
        <symbol id="check" viewBox="0 0 22 17">
          <title>Check</title>
          <path d="M2 9.77282L7.31818 15.091L20 2.40918" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </symbol>
        <symbol id="eye" viewBox="0 0 28 17">
          <title>Eye</title>
          <path d="M27.501 7.63388C27.267 7.31214 21.3588 0 13.8711 0C6.35426 0 0.475297 7.31215 0.241309 7.63388C-0.0804362 8.04335 -0.0804362 8.65769 0.241309 9.06711C0.475323 9.38886 6.38351 16.701 13.8711 16.701C21.388 16.701 27.267 9.38885 27.501 9.06711C27.8519 8.65765 27.8519 8.07251 27.501 7.63388ZM13.8711 4.73822C14.8655 4.73822 15.6845 5.55718 15.6845 6.55158C15.6845 7.54598 14.8655 8.36494 13.8711 8.36494C12.8767 8.36494 12.0578 7.54598 12.0578 6.55158C12.0578 5.55718 12.8767 4.73822 13.8711 4.73822ZM13.8711 14.3903C8.75265 14.3903 4.27761 10.0907 2.66894 8.36504C3.63414 7.31209 5.7109 5.29389 8.31392 3.86087C7.90445 4.67983 7.69959 5.58648 7.69959 6.55174C7.69959 9.94462 10.4782 12.7232 13.871 12.7232C17.2639 12.7232 20.0425 9.94462 20.0425 6.55174C20.0425 5.58653 19.8085 4.67983 19.4282 3.89006C22.0604 5.29394 24.108 7.31215 25.0731 8.3651C23.4646 10.0907 18.9894 14.3903 13.8709 14.3903L13.8711 14.3903Z"/>
        </symbol>
        <symbol id="window" viewBox="0 0 54 43">
          <title>Window</title>
          <mask id="path-1-inside-1_205_385" fill="white">
          <rect x="0.111084" y="21.8743" width="31.4286" height="20.5911" rx="2.64915"/>
          </mask>
          <rect x="0.111084" y="21.8743" width="31.4286" height="20.5911" rx="2.64915" stroke="#C4A8FF" stroke-width="10.8374" mask="url(#path-1-inside-1_205_385)"/>
          <path d="M8.60034 12.4819H35.0656C37.2747 12.4819 39.0656 14.2728 39.0656 16.4819V32.3506" stroke="#C4A8FF" stroke-width="5.41872" stroke-linecap="round"/>
          <path d="M20 3H46.4652C48.6744 3 50.4652 4.79086 50.4652 7V22.8686" stroke="#C4A8FF" stroke-width="5.41872" stroke-linecap="round"/>
        </symbol>
        <symbol id="discuss" viewBox="0 0 36 36">
          <title>Discuss</title>
          <path d="M18 0C8.05188 0 0 8.05051 0 18C0 21.1572 0.823425 24.2408 2.38705 26.9604L0.0678406 34.1625C-0.0939331 34.6646 0.0390015 35.215 0.411987 35.588C0.781403 35.9574 1.33044 36.0953 1.83746 35.9322L9.03955 33.6129C11.7592 35.1766 14.8428 36 18 36C27.9481 36 36 27.9495 36 18C36 8.05188 27.9495 0 18 0ZM18 33.1875C15.1482 33.1875 12.3684 32.3918 9.96103 30.8864C9.61469 30.67 9.18457 30.6115 8.78439 30.7403L3.58539 32.4146L5.2597 27.2156C5.3866 26.8212 5.33304 26.3905 5.11331 26.039C3.60818 23.6316 2.8125 20.8518 2.8125 18C2.8125 9.62567 9.62567 2.8125 18 2.8125C26.3743 2.8125 33.1875 9.62567 33.1875 18C33.1875 26.3743 26.3743 33.1875 18 33.1875ZM19.7578 18C19.7578 18.9706 18.9709 19.7578 18 19.7578C17.0291 19.7578 16.2422 18.9706 16.2422 18C16.2422 17.0291 17.0291 16.2422 18 16.2422C18.9709 16.2422 19.7578 17.0291 19.7578 18ZM26.7891 18C26.7891 18.9706 26.0022 19.7578 25.0312 19.7578C24.0603 19.7578 23.2734 18.9706 23.2734 18C23.2734 17.0291 24.0603 16.2422 25.0312 16.2422C26.0022 16.2422 26.7891 17.0291 26.7891 18ZM12.7266 18C12.7266 18.9706 11.9397 19.7578 10.9688 19.7578C9.99811 19.7578 9.21094 18.9706 9.21094 18C9.21094 17.0291 9.99811 16.2422 10.9688 16.2422C11.9397 16.2422 12.7266 17.0291 12.7266 18Z"/>
        </symbol>
        <symbol id="close" viewBox="0 0 34 34">
          <title>Close</title>
          <path d="M4.5 4.5L29 29" stroke-width="8.04478" stroke-linecap="round"/>
          <path d="M4.5 29L29 4.5" stroke-width="8.04478" stroke-linecap="round"/>
        </symbol>
        <symbol id="cross" viewBox="0 0 50 50">
          <title>Cross</title>
          <path d="M25.25 15.5V35" stroke-width="4.33333" stroke-linecap="round"/>
          <path d="M35 25.25L15.5 25.25" stroke-width="4.33333" stroke-linecap="round"/>
        </symbol>
        <symbol id="check" viewBox="0 0 22 17">
          <title>Check</title>
          <path d="M2 9.36364L7.31818 14.6818L20 2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </symbol>
        <symbol id="play" viewBox="0 0 30 28">
          <title>Play</title>
          <path d="M0.679993 25.445V2.93233C0.679993 1.19382 2.50443 0.0589108 4.0639 0.827344L27.9008 12.5731C29.6796 13.4496 29.6368 16.0004 27.8296 16.8167L3.99265 27.5836C2.43945 28.2852 0.679993 27.1493 0.679993 25.445Z"/>
        </symbol>
        <symbol id="circle" viewBox="0 0 78 78">
          <title>circle</title>
          <circle fill="transparent" cx="39" cy="39" r="36" stroke-width="6"/>
        </symbol>
        <symbol id="circle-small" viewBox="0 0 78 78">
          <title>circle small</title>
          <circle fill="transparent" cx="39" cy="39" r="36" stroke-width="3"/>
        </symbol>
        <symbol id="share" viewBox="0 0 24 24">
          <title>share</title>
          <path d="M5 7c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm11.122 12.065c-.073.301-.122.611-.122.935 0 2.209 1.791 4 4 4s4-1.791 4-4-1.791-4-4-4c-1.165 0-2.204.506-2.935 1.301l-5.488-2.927c-.23.636-.549 1.229-.943 1.764l5.488 2.927zm7.878-15.065c0-2.209-1.791-4-4-4s-4 1.791-4 4c0 .324.049.634.122.935l-5.488 2.927c.395.535.713 1.127.943 1.764l5.488-2.927c.731.795 1.77 1.301 2.935 1.301 2.209 0 4-1.791 4-4z"/>
        </symbol>
        <symbol id="link" viewBox="0 0 24 24">
          <title>link</title>
          <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z"/>
        </symbol>
        <symbol id="linkedin" viewBox="0 0 24 24">
          <title>linkedin</title>
          <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
        </symbol>
        <symbol id="twitter" viewBox="0 0 24 24">
          <title>twitter</title>
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
        </symbol>
        <symbol id="facebook" viewBox="0 0 24 24">
          <title>facebook</title>
          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
        </symbol>
      </defs>
    </svg>
    <!-- <svg preserveAspectRatio="xMidYMid meet">
      <use xlink:href="#arrow-left"/>
    </svg> -->
  </div>
</template>


