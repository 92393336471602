<template>
  <div class="u-wrapper-panel u-row">
    <transition name="t-fader">
      <app-panel
        v-if="!fullMode && !isTransition"
        :isPath="isPath"
        :next="next"
        :fullMode="false"
        @fullMode="fullMode = true"
        :data="data"
      />
    </transition>
    <transition name="t-fader--delayed">
      <app-panel
      v-if="fullMode && !isTransition"
      :fullMode="true"
      @fullMode="fullMode = false"
      :data="data"
    />
    </transition>
  </div>
</template>

<script>
import AppPanel from '@/components/AppPanel'
import path from '@/assets/path'
import { positionPadding } from '@/utils/positionPadding'

import { webGL } from '@/webGL/WebGL'
import { Vector3 } from 'three'
import gsap from 'gsap'

const zoomDist = {
  min: .5,
  max: 10
}

export default {
  components: {
    AppPanel
  },

  data () {
    return {
      fullMode: false,
      isTransition: false
    }
  },

  props: {
    level: {
      type: Object,
      default: () => {return {}}
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/keypointBySlug']({
        level: this.$route.params.level, 
        keypoint: this.$route.params.keypoint
      })
    },
    isPath () {
      return !!path[this.$route.params.level]
    },
    next () {
      return this.$store.getters['data/nextKeypoint']({
        level: this.level.index, 
        keypoint: this.data.index
      })
    }
  },

  created () {
    this.onInit()
  },

  methods: {
    onInit () {
      this.$hub.emit('keypoint:on')
      this.$store.commit('data/isKeypointSeen', {
        level: this.level.index, 
        keypoint: this.data.index
      })

      // keepData is to prevent data from being clear before unmount
      this.keepData = {...this.data}

      const {position, look} = this.cameraPos()
      gsap.to(webGL.camera.position, {
        x: position.x,
        y: position.y,
        z: position.z,
        duration: 2,
        ease: 'power2.inOut',
        onComplete: () => {
          this.isTransition = false
        }
      })

      gsap.to(webGL.camera.look, {
        x: look.x,
        y: look.y,
        z: look.z,
        duration: 2,
        ease: 'power2.inOut',
        onUpdate: () => {
          webGL.camera.forceUpdateLook = true
        }
      })
    },

    cameraPos () {
      const dist = zoomDist.min + (100 - this.data.zoom) * (zoomDist.max - zoomDist.min) * .01

      return positionPadding(new Vector3(parseFloat(this.data.position[0].x), parseFloat(this.data.position[0].y), parseFloat(this.data.position[0].z)), new Vector3(parseFloat(this.data.look[0].x), parseFloat(this.data.look[0].y), parseFloat(this.data.look[0].z)), dist, this.isMobile ? 0 : .20)
      // .20 is to offset but not too much centered
    }
  },

  watch: {
    $route (to, from) {
      // to deal with transition from keypoint to another one
      if (to.name === 'Keypoint') {
        this.isTransition = true
        this.onInit()
      }
    }
  },

  beforeUnmount () {
    this.$hub.emit('keypoint:off', this.keepData)
  }
}
</script>