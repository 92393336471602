import * as storage from 'localforage'

storage.config({
  driver: [storage.LOCALSTORAGE, storage.INDEXEDDB, storage.WEBSQL],
  name: 'BrightApp',
  storeName: 'BrightApp'
})

// localforage.setItem('key', 'value', callback)
// localforage.getItem('key').then()

export { storage }
