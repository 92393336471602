import { api } from '@/store/interfaces/apiCraft'

import { ref, reactive, toRefs } from 'vue'
import { storage } from '@/utils/storage'

export default {
  namespaced: true,

  state: {
    entries: [],
    levels: [],
    loaded: false
  },

  getters: {
    entries: state =>{
      return state.entries
    },
    levels: state =>{
      return state.levels
    },
    levelBySlug: state => slug => {
      return state.levels.find(level => level.slug === slug)
    },
    nextLevel: state => level => {
      const next = state.levels[level.index + 1]
      return next ? next : null
    },
    nextLevelNotSeen: state => level => {
      for (let i = 0; i < state.levels.length; i++) {
        const index = (level.index + i) % state.levels.length
        if (!state.levels[index].isSeen) {
          return state.levels[index]
        }
      }
      const index = (level.index + 1) % state.levels.length

      return state.levels[index]
    },
    keypointBySlug: (state, getters) => (args) => {
      const level = getters.levelBySlug(args.level)
      return level.keypoints.find(keypoint => keypoint.slug === args.keypoint)
    },
    nextKeypoint: (state, getters) => ({level, keypoint}) => {
      const next = state.levels[level].keypoints[keypoint + 1]
      return next ? next : state.levels[level].keypoints[0]
    },
    loaded: state =>{
      return state.loaded
    }
  },

  actions: {
    async levels ({ commit, state, dispatch }) {
      return new Promise(resolve => {
        const { request, cache } = api.getLevels()
        cache.then(async res => {
          // if (res !== null) {
          //   // commit('levels', res)
          //   await dispatch('treatLevels', res)
          //   dispatch('loaded', true)
          //   resolve()
          // }
        })

        request.then(async res => {
          // commit('levels', res)
          await dispatch('treatLevels', res)
          dispatch('loaded', true)
          resolve()
        })
      })
    },

    treatLevels ({commit}, levels) {
      return new Promise(resolve => {
        levels.forEach(async (level, index) => {
          level.keypoints = level.chapters.reduce((accumulator, current) => {
            accumulator.push(...current.keypoints)
            return accumulator
          }, [])

          level.chapters.forEach((chapter, j) => {
            chapter.index = j
          })

          level.keypoints.forEach(async (keypoint, j) => {
            const cacheSeen = await storage.getItem(`seen_level_${index}_keypoints_${j}`)
            keypoint.isSeen = cacheSeen !== null ? true : false
            keypoint.index = j
          })

          const cacheSeen = await storage.getItem(`seen_level_${index}`)
          level.isSeen = cacheSeen !== null ? true : false
          level.index = index
        })

        commit('levels', levels)
        resolve()
      })
    },

    async level ({ commit, state, dispatch }, { slug }) {
      return new Promise(resolve => {
        const { request, cache } = api.getLevel( slug )
        cache.then(res => {
          if (res !== null) {
            commit('level', res)
            dispatch('loaded', true)
            resolve()
          }
        })

        request.then(res => {
          commit('level', res)
          dispatch('loaded', true)
          resolve()
        })
      })
    },

    loaded ({ commit }) {
      setTimeout(() => {
        commit('loaded', true)
      }, 200)
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    entries (state, payload) {
      state.entries = payload
    },
    levels (state, levels) {
      state.levels = levels
    },
    isKeypointSeen (state, {level, keypoint}) {
      state.levels[level].keypoints[keypoint].isSeen = true
      storage.setItem(`seen_level_${level}_keypoints_${keypoint}`, true)
    },
    isLevelSeen (state, {level, keypoint}) {
      state.levels[level].isSeen = true
      storage.setItem(`seen_level_${level}`, true)
    }
  }
}