<template>
  <div class="menu u-w3of12 u-w12of12--xs u-bg-white u-full-height u-pad-md u-overflow-y-scroll u-invisible-scroll">
    <div class="u-row u-center u-pad-b-md">
      <app-button-round @click="$emit('close')">
        <svg class="u-full-width u-full-height u-stroke-black" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#close"/>
        </svg>
      </app-button-round>
    </div>
    <div class="menu__content">
      <div class="t-h3">
        Les épisodes
      </div>

      <level-card
        class="u-full-width"
        v-for="level in levels"
        :data="level"
        :isStatus="true"
        :isCurrent="level.slug === $route.params.level"
      />
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'
import LevelCard from '@/components/common/LevelCard'

export default {
  components: {
    AppButtonRound,
    LevelCard
  },

  computed: {
    levels () {
      return this.$store.getters['data/levels']
    }
  }
}
</script>

<style lang="stylus">
.menu
  position absolute
  right 0
  top 0
</style>