
<template>
  <button
    class="t-button u-cursor-pointer u-center t-text"
    :class="{
      'is-white': isWhite,
      'is-ghost': isGhost,
      'is-padding': !isFull,
      'is-small': isSmall,
      'u-full-width': isFull
    }"
    @click="$emit('click')"
  >
    <div class="t-button__background" />
    <template v-if="url !== ''" >
      <a v-if="isUrlAbsolute" :href="url" target="_blank" class="u-relative" :class="[contentClass, {'u-flex u-center u-middle': icon}]">
        <slot/>
      </a>
      <router-link v-else :to="url" class="u-relative" :class="[contentClass, {'u-flex u-center u-middle': icon}]">
        <slot/>
      </router-link>
    </template>
    <router-link v-else-if="to.name" :to="to" class="u-relative" :class="[contentClass, {'u-flex u-center u-middle': icon}]">
      <slot/>
    </router-link>
    <span v-else class="u-relative" :class="[contentClass, {'u-flex u-center u-middle': icon}]">
      <slot/>
    </span>
  </button>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button],

  props: {
    icon: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: false
    },
    isGhost: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isFull: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.t-button
  position relative
  color: $colors.white
  background-color transparent
  z-index 0
  padding: clamp(12px, .9em, 20px) 0 clamp(10px, .8em, 20px)
  
  &.is-padding
    padding: clamp(12px, .9em, 20px) clamp(30px, 2.8em, 60px) clamp(10px, .8em, 20px)
    
    &.is-small
      padding: clamp(10px, .7em, 12px) clamp(20px, 1em, 20px) clamp(8px, .5em, 10px)

  &:after
    content ''
    z-index -1
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    border-radius 100px
    background-color: $colors.purple
    transition: transform .5s ease-in-out-quart

  &__background
    &:before, &:after
      content ''
      z-index -1
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      border-radius 100px

    &:before
      top -7px
      left -7px
      width calc(100% + 14px)
      height calc(100% + 14px)
      border: solid 1px $colors.purple
      transform scale(1, 1) translateZ(0)
      opacity 1
      transition: transform .3s ease-in-out-quad

    &:after
      transition: opacity .3s linear
      opacity .7
      box-shadow 0px 0px 40px 0px $colors.purple, inset 0px 0px 40px 0px rgba($colors.purple, .3)

  // White
  &.is-white
    color: $colors.purple
    &:after
      background-color: $colors.white
  &.is-white &__background
    &:before
      border-color: $colors.white
    &:after
      box-shadow 0px 0px 30px 0px $colors.white, inset 0px 0px 10px 0px rgba($colors.white, .3)

  // Ghost
  &.is-ghost
    color: $colors.purple
    &:after
      background-color: transparent
  &.is-ghost &__background
    &:before
      border-color: $colors.purple
    &:after
      box-shadow none
  
  // Ghost White
  &.is-ghost.is-white
    color: $colors.white
  &.is-ghost.is-white &__background
    &:before
      border-color: $colors.white
    &:after
      box-shadow 0px 0px 30px 0px $colors.white, inset 0px 0px 10px 0px rgba($colors.white, .3)
  
  +mq($from: 'tablet')
    &:hover
      &:after
        // transform scale(1.1, 1.1)

    &:hover &__background
      &:before
        transform scale(1.05, 1.05) translateZ(0)

      &:after
        opacity 1

</style>