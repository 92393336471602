export function clamp( number, minimum = 0, maximum = 1 ) {
  if (minimum === -Infinity && maximum === Infinity) {
    return number
  }
  else if (minimum === -Infinity) {
    return Math.min(maximum, number)
  }
  else if (maximum === Infinity) {
    return Math.max(minimum, number)
  }

  return Math.min(maximum, Math.max(number, minimum))
}