import { createWebHistory } from 'vue-router'

import Level from '@/views/Level'
import Keypoint from '@/views/Keypoint'
import Homepage from '@/views/Homepage'
import messages from './locales'

const routes = [
  {
    path: '',
    name: 'Homepage',
    component: Homepage,
    meta: {
      isFull: true
    }
  },
  {
    path: '/:level',
    name: 'Level',
    component: Level,
    meta: {
      isFull: true
    },
    children: [
      {
        path: ':keypoint',
        name: 'Keypoint',
        component: Keypoint,
        meta: {
          isFull: true
        }
      }
    ]
  },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

// let langRoutes = []

// for (let i = 0; i < routes.length; i++)  {
//   for (const locale of Object.keys(messages)) {
//     const prefix = locale === 'en' ? '' : locale + '/'
//     let route = {...routes[i]}
//     route.path = '/' + prefix + route.path
//     route.name = prefix + route.name
//     route.meta = {
//       ...routes[i].meta,
//       locale: locale
//     }

//     langRoutes.push(route)
//   }
// }

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: routes
}
