export default {
	namespaced: true,

	state: {
		options: {},
		progress: 0,
	},

	mutations: {
		progress (state, payload) {
			state.progress = payload
		}
	}
}