<template>
  <div class="level-card__wrapper u-inline-block u-left">
    <router-link
      :to="{...$local('Level'), params: { level: data.slug }}"
      class="level-card u-block"
      draggable="false"
      :class="{'u-pointer-none': isCurrent, 'u-box-9by16': isVertical, 'u-box-16by9': !isVertical}"
    >
      <app-image :width="960" :height="540" v-if="data.image.length" :id="data.image[0].id" class="u-wrapper-panel u-fit-cover" />
      <div v-else class="u-bg-black" />
      <div v-if="!$device.isMobile" class="level-card__bg u-wrapper-panel" :class="{'is-forced': isCurrent}" />
      <div class="level-card__cartridge u-radius u-bg-purple u-white t-text--xsmall">
        ÉPISODE {{ data.index + 1 }}
      </div>
      <div v-if="isVertical" class="u-wrapper-panel u-pad-sm u-row u-middle u-center">
        <app-button-round v-if="data.isSeen && isStatus" :isGhost="true" class="u-inline-block" :isSmall="true" >
          <svg class="u-full-width u-full-height u-stroke-white u-fill-transparent" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#check"/>
          </svg>
        </app-button-round>
      </div>
      <div v-else class="u-wrapper-panel u-pad-sm">
        <div v-if="!isCurrent" class="u-full-width u-full-height u-relative u-overflow-hidden u-row u-bottom u-white">
          <div
            :style="{transform: `translateY(${textHeight}px) translateZ(0)`}"
            class="level-card__content u-w10of12"
            :class="[{'is-ready': textHeight !== 0}, isStatus ? 'u-w10of12' : 'u-w12of12']"
          >
            <div class="t-h5">
              {{ data.title }}
            </div>
            <div ref="text" class="t-text--xsmall u-pad-t-xxs">
              {{ data.subtitle }}
            </div>
          </div>
          <div  v-if="data.isSeen && isStatus" class="u-w2of12 u-right">
            <app-button-round :isGhost="true" class="u-inline-block" :isSmall="$device.width>1500" :isFull="$device.width<=1500">
              <svg class="u-full-width u-full-height u-stroke-white u-fill-transparent" preserveAspectRatio="xMidYMid meet">
                <use xlink:href="#check"/>
              </svg>
            </app-button-round>
          </div>
        </div>
        <div v-else class="u-full-width u-full-height u-relative u-row u-middle u-white">
          <div class="u-full-width">
            <div class="u-center u-row">
              <app-button-round :isSmall="true">
                <svg class="u-full-width u-full-height u-fill-purple">
                  <use xlink:href="#play"/>
                </svg>
              </app-button-round>
            </div>
            <div class="u-center t-text u-marg-t-sm">
              EN COURS D’EXPLORATION
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    AppButtonRound
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    isStatus: {
      type: Boolean,
      default: true
    },
    isCurrent: {
      type: Boolean,
      default: false
    },
    isVertical: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      textHeight: 0
    }
  },

  mounted () {
    this.onResize()
  },

  methods: {
    onResize () {
      if (!this.isCurrent && !this.isVertical) {
        this.textHeight = this.$refs.text.offsetHeight
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'
@import '~@/styles/settings/variables'

.level-card
  &__wrapper
    white-space normal
    padding-top 4em

  &__cartridge
    position absolute
    top 0
    right $spacings.sm
    transform translateY(-50%)
    padding .4em .8em

  &__bg
    opacity .2
    background: linear-gradient(0deg, #000000 52.2%, rgba(0, 0, 0, 0) 121.15%)
    transition opacity .5s linear
    
    &.is-forced
      opacity .9

  &__content.is-ready
    transition transform .5s ease-in-out-quart
  
  &:hover &__content
    transform translateY(0) translateZ(0) !important
  
  &:hover &__bg
    opacity .9

</style>