import Apollo from '@/store/interfaces/Apollo'
import gql from 'graphql-tag'
import { storage } from '@/utils/storage'

import { keypoint, chapter, levels } from './parts'

const apollo = new Apollo(process.env.VUE_APP_CRAFT_API, false)
apollo.setToken('Bearer ' + process.env.VUE_APP_CRAFT_TOKEN)
const graphqlClient = apollo.client

const site = process.env.VUE_APP_CRAFT_WEBSITE

class APICraft {
  getEntries (ids) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchLevels {
            levels: entries(site: "${site}", level: 1) {
              ... on stKeypoint_stKeypoint_Entry {
                ${levels}
                chapters: children {
                  ... on stKeypoint_stKeypoint_Entry {
                    ${chapter}
                    keypoints: children {
                      ... on stKeypoint_stKeypoint_Entry {
                        ${keypoint}
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { id: ids }
      })
      .then(res => {
        const result = res.data.levels

        resolve(result)
        storage.setItem( `fetchLevels_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchLevels_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getLevels () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchLevels {
            levels: entries(site: "${site}", level: 1) {
              ... on stKeypoint_stKeypoint_Entry {
                ${levels}
                chapters: children {
                  ... on stKeypoint_stKeypoint_Entry {
                    ${chapter}
                    keypoints: children {
                      ... on stKeypoint_stKeypoint_Entry {
                        ${keypoint}
                      }
                    }
                  }
                }
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.levels

        resolve(result)
        storage.setItem( `fetchLevels_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchLevels_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getLevel (slug) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchLevel ($slug: [String]) {
            level: entry(site: "${site}", level: 1, slug: $slug) {
              ... on stKeypoint_stKeypoint_Entry {
                ${levels}
                chapters: children {
                  ... on stKeypoint_stKeypoint_Entry {
                    ${chapter}
                    keypoints: children {
                      ... on stKeypoint_stKeypoint_Entry {
                        ${keypoint}
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { slug: slug }
      })
      .then(res => {
        const result = res.data.level

        resolve(result)
        storage.setItem( `fetchLevel_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchLevel_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  imgSize ({id, width, height}) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      let query = {}

      if (width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int, $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true, height: $height)
                }
              }
            }
          `,
          variables: { id, width, height }
        }
      }
      else if (width && !height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true)
                }
              }
            }
          `,
          variables: { id, width }
        }
      }
      else if (!width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(height: $height, immediately: true)
                }
              }
            }
          `,
          variables: { id, height }
        }
      }
      else {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument])  {
              asset(id: $id) {
                ... on default_Asset {
                  url
                }
              }
            }
          `,
          variables: { id }
        }
      }

      graphqlClient.query(query)
        .then(res => {
          resolve(res.data.asset.url)

          const result = res.data.asset.url

          resolve(result)
          storage.setItem( `fetchImg_${JSON.stringify(args)}`, result)
        })
        .catch(err => {
          console.error('Error catching global params ', err)
        })
    })

    const cache = storage.getItem(`fetchImg_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }
}

export const api = new APICraft()