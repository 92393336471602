<template>
  <div class="u-absolute" ref="root" :style="{opacity: opacity}">
    <div class="chapter u-pad-xs u-overflow-hidden">
      <div class="chapter__indice t-text--small u-white">
        0{{ data.index + 1 }}
      </div>
      <div class="t-text">
        {{ data.title }}
      </div>
      <div class="chapter__text u-marg-t-xxs t-medium u-white-space" v-for="text in data.multipleTexts">
        <div v-html="text.text"/>
      </div>
    </div>
  </div>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'
import Opacity2D from './Opacity2D'

import { webGL } from '@/webGL/WebGL'

export default {
  mixins: [Object2d, Opacity2D],

  props: {
    index: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => { return {}}
    }
  },

  created () {
    this._camera = webGL.camera

    this.opacityOut = {
      min: 0,
      max: 0
    }
  },

  methods: {
    onUpdate () {
      this.computeOpacity()
      this._onUpdate()
    }
  },
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/mixins/viewport-sizer'

.chapter
  position absolute
  width 300px
  left -150px
  background-color #f5ea5c
  border-radius 15px
  box-shadow: inset -2px 2px 5px 0px rgba($colors.black, .1), -1px 1px 5px 1px rgba(#f5ea5c, .7)

  +mq($until: 'tablet')
    width 200px
    left -100px
  
  &__indice
    position absolute
    top -.5em
    right 30px
    padding .6em .7em .4em
    background: $colors.purple
    border-radius 50%

    +mq($until: 'tablet')
      padding 1em .8em .4em
      top -.9em
  
  &__text
    viewportSizer('font-size', 10, 1920, 8px, 12px)
    viewportSizer('line-height', 12, 1920, 10px, 15px)

</style>