<template>
  <component
    :is="to.name ? 'router-link' : 'div'"
    :to="to"
    ref="root"
    class="keypoint u-cursor-pointer"
    :class="{'is-seen': isSeen, 'is-visible': isTitleVisible}"
    @click="$emit('click')"
    :style="{opacity: opacity}"
  >
    <div class="keypoint__dot">
      <div class="keypoint__dot__main u-box-1by1" />
      <div class="keypoint__dot__pulse" />
      <div class="keypoint__dot__heart">
        <svg class="keypoint__dot__heart__svg u-wrapper-panel u-fill-transparent" :class="isSeen ? 'u-stroke-yellow' : 'u-stroke-purple'" >
          <use :xlink:href="isSeen ? '#check' : '#cross'"/>
        </svg>
      </div>
    </div>
    <div class="keypoint__name u-black">
      <div class="u-center">
        <div class="keypoint__name__title t-text--small u-inline-block u-bg-white">
          {{ title }}
        </div>
      </div>
      <div class="u-center">
        <div class="keypoint__name__subtitle u-inline-block u-bg-white">
          {{ subtitle }}
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { webGL } from '@/webGL/WebGL'

import Object2d from '@/plugins/Dom3d/mixin2d'
import Opacity2D from './Opacity2D'

export default {
  mixins: [Object2d, Opacity2D],

  emits: ['click'],

  created () {
    this._camera = webGL.camera
  },

  props: {
    to: {
      type: Object,
      default: () => {return {}}
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    isSeen: {
      type: Boolean,
      default: false
    },
    isTitleVisible: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    onUpdate () {
      this.computeOpacity()
      this._onUpdate()
    }
  },
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/easings'

.keypoint
  position absolute
  
  &:hover
    z-index 1

  &:hover &__name
    opacity 1
  
  &.is-visible &__name
    opacity 1
    pointer-events all

  &__name
    position absolute
    bottom 40px
    width 200px
    left calc( 50% - 100px)
    pointer-events none
    opacity 0
    transition opacity .3s linear

    +mq($until: 'desktop')
      opacity 0

    &__title, &__subtitle
      border-radius 15px
      padding 3px 10px 1px    

    &__subtitle
      padding 3px 10px 3px
      font-family circular
      font-weight $weights.bold // 700
      margin-top -1em
      viewportSizer('font-size', 10, 1920, 7px, 12px)

      +mq($until: 'desktop')
        margin-top -1.7em

  &__dot
    border-radius 50%
    width 40px
    opacity 1

    &__main
      border-radius 50%
      background-color: $colors.yellow
      opacity .5

    &__heart
      position absolute
      background: $colors.yellow
      height 68%
      width 68%
      top 16%
      left 16%
      border-radius 50%

      &__svg
        padding 0px

    &__pulse
      position absolute
      border: solid 2px $colors.yellow
      height 130%
      width 130%
      top -15%
      left -15%
      border-radius 50%
      opacity 0
      will-change opacity, transform
      animation 2s ease-in-out-quad infinite pulse
  
  &.is-seen &__dot
    &__main
      background-color: $colors.purple

    &__heart
      background: $colors.purple

      &__svg
        padding 5px

    &__pulse
      border: solid 2px $colors.purple
    

@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0
</style>