// import '@/mixins/extendedComponent'

import { ref } from 'vue'

import { webGL } from '@/webGL/WebGL'

import { engine, LoaderManager, GLTFLoader } from 'shimmer'

import data from '@/assets/data'

export default {

  created () {
    window.addEventListener('resize', this._onResize)
    window.addEventListener('scroll', this._onScroll)
    window.addEventListener('keydown', this._onKeyDown)

    this.$device._onResize()
    this.$hub.emit('resize', event)

    GLTFLoader.ktx = webGL.renderer.renderer

    LoaderManager.config = data.assets
  },

  mounted () {
    webGL.el = this.$refs.containerWebGL
    engine.el = this.$refs.containerWebGL

    // this.$dom3d.onResize(this.$device.width, this.$device.height)
    // this.$dom3d.attachTo(this.$refs.containerCSS3D)
    // this.$dom3d.setCamera(webGL.camera)
    webGL.resize({ width: this.$device.width, height: this.$device.height })
    engine.camera = webGL.camera

    this._onResize()

    window.addEventListener(this.$device.pointerdown, engine.pointerdown)
    window.addEventListener(this.$device.pointermove, engine.pointermove)
  },

  methods: {
    onUpdate (time) {
      this._onUpdate(time)
    },

    _onUpdate ({ delta, elapsed }) {
      webGL.update()
      this.$dom3d.update()
    },

    _onResize (event) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$device._onResize()
        // this.$dom3d.onResize(this.$device.width, this.$device.height)
        webGL.resize({ width: this.$device.width, height: this.$device.height })
        this.$hub.emit('resize', event)
      }, 200)
    },

    _onScroll (event) {
      this.$device._onScroll()
      this.$hub.emit('scroll', event)
    },

    _onKeyDown (event) {
      this.$hub.emit('keydown', event.keyCode)
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.$hub.emit('keydown:escape', event.keyCode)
      }
    }
  }
}
