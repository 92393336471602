<template>
  <div
    id="main"
    ref="main"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <div ref="containerWebGL" class="u-wrapper-panel"/>
    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component v-if="isLoaded" :is="Component" />
      </transition>
    </router-view>

    <debugger v-if="$route.query.debug" />

    <cookie />

    <transition :duration="3000" name="custom">
      <loader v-if="!isLoaded" />
    </transition>
    
    <transition :duration="isTransition ? 100 : 2000" name="custom">
      <transition-screen v-if="isTransition" />
    </transition>

    <!-- <div ref="containerCSS3D" class="u-wrapper-panel u-overflow-hidden"/> -->
    <img alt="Vue logo" src="./assets/logo.png">

  </div>
</template>

<script>
import App from './App'

import SymbolsSvg from '@/components/SymbolsSvg'
import Debugger from '@/components/Debugger'
import Loader from '@/components/common/Loader'
import TransitionScreen from '@/components/common/TransitionScreen'

import Cookie from '@/components/common/cookie'

import { webGL } from '@/webGL/WebGL'

export default {
  name: 'App',

  mixins: [App],

  computed: {
    isLoaded () {
      return this.$store.getters['data/loaded']
    }
  },

  components: {
    SymbolsSvg,
    Debugger,
    Loader,
    Cookie,
    TransitionScreen
  },

  data () {
    return {
      isTransition: false
    }
  },

  created () {
    this.$store.dispatch('data/levels')
    this.$hub.on('transition:on', () => {
      this.isTransition = true
    })
    this.$hub.on('transition:off', () => {
      this.isTransition = false
    })
  },

  mounted () {
    webGL.isMobile = this.$device.isMobile
  },

  watch: {
    // async $route (to, from) {
    //   if (!from.name && !to.query.debug) {
    //     this.$router.push(this.$local('Homepage'))
    //   }
    // }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh
  background linear-gradient(180deg, #933DEB 0%, #D294FF 100%)

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto


</style>