<template>
  <div class="u-wrapper-panel">
    <transition-group name="t-fader">
      <keypoint
        v-for="keypoint in keypoints"
        v-if="$route.name.includes('Level')"
        :progress="progress"
        :position="keypoint.position[0]"
        :baseProgress="keypoint.progress * .01"
        :key="keypoint.slug"
        :isSeen="keypoint.isSeen"
        :isTitleVisible="true"
        :title="keypoint.title"
        :subtitle="keypoint.subtitle"
        :level="level"
        :to="{...$local('Keypoint'), params: {level: $route.params.level, keypoint: keypoint.slug}}"
      />
    </transition-group>
    <transition-group name="t-fader">
      <chapter
        v-for="(chapter, index) in chapters"
        v-if="$route.name.includes('Level')"
        :progress="progress"
        :baseProgress="chapter.progress * .01"
        :position="chapter.position"
        :key="chapter.slug"
        :data="chapter"
        :index="index"
      />
    </transition-group>
    <transition name="t-fader">
      <div v-if="progress < .05" class="level-path__scroll u-center t-text u-white">
        <img
          v-for="(anim, i) in animate"
          src="/images/scroll.png"
          class="level-path__scroll__img"
          :class="['level-path__scroll__img--' + i, {'is-animate': anim}]"
        >
        Scrollez pour avancer
      </div>
    </transition>
  </div>
</template>

<script>
import Keypoint from '@/components/Keypoint'
import Chapter from '@/components/Chapter'

import { clamp, mapper } from '@/utils/Maths'

import { Vector2 } from 'three'

import { CameraPath } from '@/webGL/CameraPath'

export default {
  components: {
    Keypoint,
    Chapter
  },

  props: {
    level: {
      type: Object,
      default: () => {return {}}
    },
    keypoints: {
      type: Array,
      default: () => {return []}
    },
    isActive: {
      type: Boolean,
      default: true
    },
    isMenu: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    chapters () {
      const chapters = [...this.level.chapters]

      chapters.forEach(chapter => {
        chapter.position = this.cameraPath.getPointAtProgess(chapter.progress * .01)
        chapter.position.y += .5
      })

      return chapters
    },
  },

  data () {
    return {
      progress: 0,
      animate: [false, false, false]
    }
  },

  created () {
    this.cameraPath = new CameraPath({
      pathName: this.$route.params.level,
      isTouch: this.$device.isTouch
    })

    this.isAccelerometer = false

    if (this.$route.query.debug) {
      this.cameraPath.debug = true
    }

    this.accelerometer = {
      sum: new Vector2(),
      normalize: new Vector2(),
      smooth: new Vector2(),
      base: new Vector2()
    }
    this.smooth = {
      currentPos: new Vector2(),
      lastPos: new Vector2(),
      delta: new Vector2(),
      pos: new Vector2()
    }

    this.$hub.on('mousepos:reinit', () => {
      this.smooth.currentPos.x = this.smooth.currentPos.y = 0
      this.smooth.pos.x = this.smooth.pos.y = 0

      this.accelerometer.sum.x = this.accelerometer.sum.y = 0
      this.accelerometer.normalize.x = this.accelerometer.normalize.y = 0
      this.accelerometer.smooth.x = this.accelerometer.smooth.y = 0
      this.accelerometer.base.x = this.accelerometer.base.y = 0
    })

    if (window.DeviceOrientationEvent) {
      this.isAccelerometer = true
      window.addEventListener('deviceorientation', this.onMotion)
    }

    for (let i = 0; i < this.animate.length ; i++) {
      setTimeout(() => {
        this.animate[i] = true
      }, 120 * i)
    }
  },

  mounted () {
    this.cameraPath.isMobile = this.$device.isMobile
    window.addEventListener('pointermove', this.onPointerMove)
  },

  methods: {
    onMotion (event) {
      const alpha = event.alpha < 180 ? event.alpha : event.alpha - 360
      const beta = event.beta < 180 ? event.beta : event.beta - 360

      if (this.accelerometer.base.x === 0 && this.accelerometer.base.y === 0) {
        this.accelerometer.base.x = alpha
        this.accelerometer.base.y = beta
      }

      // 8 is an orbitrary value. Lower it will lower max value
      this.accelerometer.sum.x = clamp(this.accelerometer.base.x - alpha, -30, 30)
      this.accelerometer.sum.y = clamp(-(this.accelerometer.base.y - beta), -30, 30)

      this.accelerometer.normalize.x = mapper(this.accelerometer.sum.x, -30, 30, -1, 1)
      this.accelerometer.normalize.y = mapper(this.accelerometer.sum.y, -30, 30, -1, 1)
    },

    computedSmoothAccelerometer () {
      this.accelerometer.smooth.x += (this.accelerometer.normalize.x - this.accelerometer.smooth.x) * .3
      this.accelerometer.smooth.y += (this.accelerometer.normalize.y - this.accelerometer.smooth.y) * .3

      this.cameraPath.accelerometer = this.accelerometer.smooth
    },

    onUpdate (time) {
      if (!this.isActive) { return }

      this.progress = this.cameraPath.scroll
      this.$store.commit('global/progress', this.progress)

      if (this.progress > 0.85) {
        this.$emit('end')
      }

      if (!this.$device.isTouch) {
        this.computedSmoothMouse()
      }

      if (this.isAccelerometer) {
        this.computedSmoothAccelerometer()
      }
    },

    computedSmoothMouse () {
      this.smooth.currentPos.x += (this.smooth.pos.x - this.smooth.currentPos.x) * .1
      this.smooth.currentPos.y += (this.smooth.pos.y - this.smooth.currentPos.y) * .1
      this.smooth.delta.subVectors(this.smooth.currentPos, this.smooth.lastPos)
      this.smooth.lastPos.copy(this.smooth.currentPos)
      this.cameraPath.mousePos = this.smooth.currentPos
    },

    onPointerMove (event) {
      let pos = this.$device.getPointerPosition(event)
      this.smooth.pos.copy(new Vector2(( pos.x / this.$device.width ) * 2 - 1, - ( pos.y / this.$device.height ) * 2 + 1))
    }
  },

  watch: {
    isMenu () {
      this.cameraPath.active = !this.isMenu
    }
  },

  beforeUnmount () {
    this.cameraPath.onDestroy()
    window.removeEventListener('pointermove', this.onPointerMove)
  },
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'
@import '~@/styles/settings/mixins/mq'

.level
  &__logo
    position absolute
    width 90px
    top: $spacings.md
    left: $spacings.md

    +mq($until: 'tablet')
      width 60px

  &__menu
    position absolute
    top: $spacings.md
    right: $spacings.md
    
    &__bg
      opacity .7
      background-color: rgba($colors.purple, 0.7)

  &__contact--mobile
    position absolute
    top: $spacings.md
    right: 110px

  &__contact
    position absolute
    bottom: $spacings.md
    left: $spacings.md

    &__icon
      height 1.5em
      width 1.5em

.level-path__scroll
  position absolute
  left calc(50% - 250px)
  width 500px
  bottom: 100px
  
  &__img
    position absolute
    bottom calc(100% + 10px)
    left calc(50% - 17px)
    width 34px

    &.is-animate
      animation: 5s ease-in-out-quad infinite scroller
    
    &--1
      bottom calc(100% + 25px)
    
    &--2
      bottom calc(100% + 40px)


@keyframes scroller
  0%
    transform scale3d(.5, .5, 1)
  9%
    transform scale3d(1, 1, 1)
  18%
    transform scale3d(.5, .5, 1)
  100%
    transform scale3d(.5, .5, 1)

// .btn__move
//   position absolute
//   bottom 20px
//   left calc(50% - 100px)
//   width 200px

</style>