<template>
  <div class="u-wrapper-panel">
    <template v-if="isReady">
      <level-path
        :level="level"
        :keypoints="keypoints"
        :isActive="isActive"
        :isMenu="isMenu"
        @end="forceEndScreen = true"
        v-if="isPath"
      />
      <level-orbital
        :level="level"
        :keypoints="keypoints"
        :isActive="isActive"
        :isMenu="isMenu"
        :keypointsSeen="keypointsSeen"
        v-else
      />
    </template>
    <router-link class="level__logo u-z-back" :to="$local('Homepage')">
      <img class="u-full-width" src="/images/bright__logo--white.png">
    </router-link>

    <transition name="t-fader">
      <share v-if="!isEndScreen" @contact="isContact = true" />
    </transition>

    <div class="level__menu u-z-back">
      <app-button-round @click="isMenu = true">
        <svg class="u-full-width u-full-height u-fill-transparent" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#window"/>
        </svg>
      </app-button-round>
      <div v-if="!$device.isMobile" class="u-center u-white t-text u-marg-t-xs">
        Episodes
      </div>
    </div>
    <transition name="t-fader">
      <div v-if="isMenu" @click="isMenu = false" class="u-z-back level__menu__bg u-wrapper-panel" />
    </transition>
    <transition name="t-translate--right">
      <app-menu v-if="isMenu" @close="isMenu = false" class="u-z-back" />
    </transition>

    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component :level="level" :is="Component" />
      </transition>
    </router-view>

    <transition name="t-fader">
      <app-end-screen @contact="isContact = true" :nextLevel="nextLevel" @close="isEndScreenVisible = false" v-if="isEndScreen" />
    </transition>

    <transition name="t-fader">
      <app-iframe @close="isContact = false" v-if="isContact" />
    </transition>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppButtonRound from '@/components/common/AppButtonRound'

import { City } from '@/webGL/objects/City'
import { webGL } from '@/webGL/WebGL'
  
import AppMenu from '@/components/AppMenu'
import Share from '@/components/common/Share'
import AppIframe from '@/components/common/AppIframe'
import AppEndScreen from '@/components/AppEndScreen'

import path from '@/assets/path'
import LevelPath from '@/views/LevelPath'
import LevelOrbital from '@/views/LevelOrbital'

export default {
  components: {
    AppButton,
    AppButtonRound,
    AppMenu,
    LevelPath,
    LevelOrbital,
    Share,
    AppIframe,
    AppEndScreen
  },

  computed: {
    level () {
      return this.$store.getters['data/levelBySlug'](this.$route.params.level)
    },
    keypoints () {
      return this.level.keypoints
    },
    nextLevel () {
      return this.$store.getters['data/nextLevelNotSeen'](this.level)
    },
    // CHECK HERE
    isActive () { 
      return this.$route.name.includes('Level')
    },
    isPath () {
      return !!path[this.$route.params.level]
    },
    keypointsSeen () {
      return this.keypoints.filter(keypoint => keypoint.isSeen).length
    },
    isEndScreen () {
      return (this.keypoints.length === this.keypointsSeen || this.forceEndScreen) && this.isActive && this.isEndScreenVisible
    }
  },

  data () {
    return {
      isMenu: false,
      isReady: false,
      isContact: false,
      forceEndScreen: false,
      isEndScreenVisible: true
    }
  },

  created () {
    this.$store.commit('data/isLevelSeen', { level: this.level.index })

    if (this.keypoints.length === this.keypointsSeen) {
      this.isEndScreenVisible = false
    }

    this.askToMove()
    this.onInit()
  },

  methods: {
    onInit () {
      this.delayPromise = new Promise(resolve => {
        setTimeout(resolve, 2000)
      })
      this.city = new City(this.$route.params.level)
      webGL.scene.add(this.city)
      // this.city.promise.then(() => {
      // })

      Promise.all([this.delayPromise, this.city.promise]).then(() => {
        this.isReady = true
        this.$hub.emit('transition:off')
      })
    },
    onUnmount () {
      this.isMenu = false
      this.isReady = false
      this.city.onDestroy()
      webGL.scene.remove(this.city)
    },
    askToMove () {
      if ((this.$device.isIphone || this.$device.isIpad) && DeviceOrientationEvent.requestPermission) {
        DeviceOrientationEvent.requestPermission()
      }
    }
  },

  beforeUnmount () {
    this.onUnmount()
  },

  watch: {
    $route (to, from) {
      // from.name === 'Keypoint' && from.params.level !== to.params.level
      // is for when you are in keypoint and use menu to go to another level
      if ((to.name === 'Level' && from.name === 'Level') || (from.name === 'Keypoint' && from.params.level !== to.params.level)) {
        this.onUnmount()
        requestAnimationFrame(() => {
          this.onInit()
        })
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.level
  &__logo
    position absolute
    width 90px
    top: $spacings.md
    left: $spacings.md

    +mq($until: 'tablet')
      width 60px

  &__menu
    position absolute
    top: $spacings.md
    right: $spacings.md
    
    &__bg
      opacity .7
      background-color: rgba($colors.purple, 0.7)

    &__icon
      height 1.5em
      width 1.5em

// .btn__move
//   position absolute
//   bottom 20px
//   left calc(50% - 100px)
//   width 200px

</style>