<template>
  <div class="u-wrapper-panel" @mousemove="onMouseMove">
    <transition-group name="t-fader">
      <keypoint
        v-for="keypoint in keypoints"
        v-if="$route.name.includes('Level') && !isIntro"
        :position="keypoint.position[0]"
        :key="keypoint.slug"
        :isSeen="keypoint.isSeen"
        :title="keypoint.title"
        :subtitle="keypoint.subtitle"
        :to="{...$local('Keypoint'), params: {level: $route.params.level, keypoint: keypoint.slug}}"
      />
    </transition-group>

    <transition name="t-fader">
      <level-info
        v-if="isIntro"
        :title="level.title"
        :subTitle="level.subtitle"
        :index="level.index"
      />
    </transition>
    <transition name="t-fader">
      <level-info
        v-if="!isIntro && $route.name.includes('Level') && !$device.isMobile"
        :title="level.title"
        :subTitle="level.subtitle"
        :index="level.index"
        :big="false"
        :total="keypoints.length"
        :seen="keypointsSeen"
        class="u-z-back"
      />
    </transition>
  </div>
</template>

<script>
import data from '@/assets/data'

import { clamp, mapper } from '@/utils/Maths'

import LevelInfo from '@/components/LevelInfo'
import Keypoint from '@/components/Keypoint'
import { webGL } from '@/webGL/WebGL'

import { Vector2 } from 'three'
import gsap from 'gsap'

import { polarToCartesian, cartesianToPolar } from '@/utils/Maths'

export default {
  props: {
    level: {
      type: Object,
      default: () => {return {}}
    },
    keypoints: {
      type: Array,
      default: () => {return []}
    },
    isActive: {
      type: Boolean,
      default: true
    },
    isMenu: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    keypointsSeen () {
      return this.keypoints.filter(keypoint => keypoint.isSeen).length
    }
  },

  data () {
    return {
      isIntro: true
    }
  },

  components: {
    Keypoint,
    LevelInfo
  },

  created () {
    this.isTransition = false

    this.datum = data.assets.find(datum => {
      return this.$route.params.level === datum.name
    })

    webGL.camera.position.set(...this.datum.position)
    webGL.camera.look.set(0, 0, 0)
    webGL.camera.forceUpdateLook = true

    this.basePolar = cartesianToPolar(webGL.camera.position.x, webGL.camera.position.y, webGL.camera.position.z)

    this.smooth = {
      currentPos: new Vector2(),
      lastPos: new Vector2(),
      delta: new Vector2(),
      pos: new Vector2(),
      touch: new Vector2()
    }

    this.accelerometer = {
      sum: new Vector2(),
      normalize: new Vector2(),
      smooth: new Vector2(),
      base: new Vector2()
    }

    if (window.DeviceOrientationEvent) {
      this.isAccelerometer = true
      window.addEventListener('deviceorientation', this.onMotion)
    }
  },

  mounted () {
    if (!this.$device.isTouch) {
      window.addEventListener(this.$device.pointermove, this.onPointerMove)
    }
    else {
      window.addEventListener(this.$device.pointerdown, this.onTouchPointerdown)
      window.addEventListener(this.$device.pointerup, this.onTouchPointerup)
      window.addEventListener(this.$device.pointermove, this.onTouchPointermove)
    }

    setTimeout(() => {
      this.isIntro = false
    }, 4000)
  },

  methods: {
    onMotion (event) {
      const alpha = event.alpha < 180 ? event.alpha : event.alpha - 360
      const beta = event.beta < 180 ? event.beta : event.beta - 360

      if (this.accelerometer.base.x === 0 && this.accelerometer.base.y === 0) {
        this.accelerometer.base.x = alpha
        this.accelerometer.base.y = beta
      }

      // 8 is an orbitrary value. Lower it will lower max value
      this.accelerometer.sum.x = clamp(this.accelerometer.base.x - alpha, -30, 30)
      this.accelerometer.sum.y = clamp(-(this.accelerometer.base.y - beta), -30, 30)

      this.accelerometer.normalize.x = mapper(this.accelerometer.sum.x, -30, 30, -1, 1)
      this.accelerometer.normalize.y = mapper(this.accelerometer.sum.y, -30, 30, -1, 1)
    },

    onUpdate () {
      if (this.isActive && !this.isTransition && !this.$route.query.debug) {
        this.computedSmoothMouse()

        if (this.isAccelerometer) {
          this.computedSmoothAccelerometer()
        }
      }
    },

    computedSmoothAccelerometer () {
      this.accelerometer.smooth.x += (this.accelerometer.normalize.x - this.accelerometer.smooth.x) * .1
      this.accelerometer.smooth.y += (this.accelerometer.normalize.y - this.accelerometer.smooth.y) * .1
    },

    computedSmoothMouse () {
      this.smooth.currentPos.x += (this.smooth.pos.x - this.smooth.currentPos.x) * .05
      this.smooth.currentPos.y += (this.smooth.pos.y - this.smooth.currentPos.y) * .05
      this.smooth.delta.subVectors(this.smooth.currentPos, this.smooth.lastPos)
      this.smooth.lastPos.copy(this.smooth.currentPos)

      const polar = this.basePolar.polar - (this.smooth.currentPos.x + this.accelerometer.smooth.x) * this.datum.angle[0]
      const azimuth = this.basePolar.azimuth - (this.smooth.currentPos.y + this.accelerometer.smooth.y) * this.datum.angle[1]

      const position = polarToCartesian(this.basePolar.radius, polar, azimuth)

      webGL.camera.position.x = position.x
      webGL.camera.position.y = position.y
      webGL.camera.position.z = position.z
      webGL.camera.forceUpdateLook = true
    },

    onPointerMove (event) {
      let pos = this.$device.getPointerPosition(event)
      this.smooth.pos.copy(new Vector2(( pos.x / this.$device.width ) * 2 - 1, - ( pos.y / this.$device.height ) * 2 + 1))
    },

    onTouchPointerdown (event) {
      this.isPointerdown = true
      let pos = this.$device.getPointerPosition(event)
      this.smooth.touch = new Vector2(( pos.x / this.$device.width ) * 2 - 1, - ( pos.y / this.$device.height ) * 2 + 1)
    },

    onTouchPointerup (event) {
      this.isPointerdown = false
    },

    onTouchPointermove (event) {
      let pos = this.$device.getPointerPosition(event)
      pos = new Vector2(( pos.x / this.$device.width ) * 2 - 1, - ( pos.y / this.$device.height ) * 2 + 1)
      let detla = this.smooth.touch.clone().sub(pos)

      this.smooth.pos.add(detla.multiplyScalar(this.datum.multiply))

      if (this.datum.touchMin && this.datum.touchMax) {
        this.smooth.pos.x = clamp(this.smooth.pos.x, this.datum.touchMin[0], this.datum.touchMax[0])
        this.smooth.pos.y = clamp(this.smooth.pos.y, this.datum.touchMin[1], this.datum.touchMax[1])
      }

      this.smooth.touch = pos
    },

    reinit () {
      this.smooth.currentPos = new Vector2(0, 0)
      this.smooth.lastPos = new Vector2(0, 0)
      this.smooth.delta = new Vector2(0, 0)
      this.smooth.pos = new Vector2(0, 0)

      this.accelerometer.sum.x = this.accelerometer.sum.y = 0
      this.accelerometer.normalize.x = this.accelerometer.normalize.y = 0
      this.accelerometer.smooth.x = this.accelerometer.smooth.y = 0
      this.accelerometer.base.x = this.accelerometer.base.y = 0
    }
  },

  watch: {
    $route (to ,from) {
      if (from.name === 'Keypoint' && to.name === 'Level') {
        this.isTransition = true
        gsap.to(webGL.camera.position, {
          x: this.datum.position[0],
          y: this.datum.position[1],
          z: this.datum.position[2],
          duration: 2,
          ease: 'power2.inOut',
          onComplete: () => {
            this.isTransition = false
            this.reinit()
          }
        })

        gsap.to(webGL.camera.look, {
          x: 0,
          y: 0,
          z: 0,
          duration: 2,
          ease: 'power2.inOut',
          onUpdate: () => {
            webGL.camera.forceUpdateLook = true
          }
        })
      }
    }
  },

  beforeUnmount () {
    if (this.isAccelerometer) {
      window.removeEventListener('deviceorientation', this.onMotion)
    }
    window.removeEventListener('pointermove', this.onPointerMove)
  }
}
</script>

<style lang="stylus">
  
</style>