<template>
  <div
    class="u-full-height u-row u-middle u-bottom--xs"
    :class="{
      'u-w4of12 u-marg-l-7of12': !$device.isMobile && !fullMode,
      'u-w10of12 u-marg-l-1of12': !$device.isMobile && fullMode,
      'u-w12of12': $device.isMobile
    }"
  >
    <div class="panel u-relative u-bg-white u-full-width">
      <div class="panel__content u-overflow-y-scroll u-invisible-scroll" :class="fullMode ? 'u-pad-lg u-pad-md--xs' : 'u-pad-md'">
        <div class="u-row u-between">
          <div class="panel__content__title" :class="{'is-not-full': !isPath}">
            <div class="u-purple" :class="fullMode ? 't-h1' : 't-h2'">
              {{ data.title }}
            </div>
            <div class="u-purple u-marg-t-sm u-marg-t-xs--sm" :class="fullMode ? 't-h2' : 't-h3'">
              {{ data.subtitle }}
            </div>
          </div>
          <app-button-round v-if="!fullMode && !isPath" :to="{...$local('Level'), params: $route.params }" >
            <svg class="u-full-width u-full-height u-stroke-black" preserveAspectRatio="xMidYMid meet">
              <use xlink:href="#close"/>
            </svg>
          </app-button-round>
        </div>

        <template v-if="!$device.isMobile || fullMode">
          <div v-if="fullMode && data.image.length" class="u-box-16by9 u-radius u-marg-t-md u-overflow-hidden u-bg-purple">
            <app-image :width="2000" :height="1125" :id="data.image[0].id" class="u-wrapper-panel u-fit-cover" />
          </div>

          <div class="t-wysiwyg u-white-space" :class="fullMode ? 'u-marg-t-md' : 'u-marg-t-sm'" v-html="data.multipleTexts[0].text" />
          <div v-if="fullMode" class="u-marg-t-sm t-wysiwyg t-wysiwyg--medium u-white-space" v-for="(text, index) in data.multipleTexts">
            <div v-if="index !== 0" v-html="text.text"/>
          </div>
        </template>

        <div v-if="!fullMode" class="u-row u-marg-t-md">
          <div class="u-w5of12">
            <app-button :isGhost="true" :isFull="true" @click="$emit('fullMode', true)">
              En Savoir plus
            </app-button>
          </div>
          <div class="u-w6of12 u-marg-l-1of12">
            <app-button v-if="isPath" :isFull="true" :to="{...$local('Level'), params: $route.params }">
              Continuer l’exploration
            </app-button>
            <app-button v-else :icon="true" :isFull="true" :to="{...$local('Keypoint'), params: {level: $route.params.level, keypoint: next.slug}}">
              <div>Prochain article</div>
              <svg class="panel__shitty-icon u-fill-white u-marg-l-xs" preserveAspectRatio="xMidYMid meet">
                <use xlink:href="#arrow-right"/>
              </svg>
            </app-button>
          </div>
        </div>
      </div>
      <app-button-round v-if="fullMode" :to="{...$local('Level'), params: $route.params }" class="panel__close">
        <svg class="u-full-width u-full-height u-stroke-black" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#close"/>
        </svg>
      </app-button-round>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    AppButton,
    AppButtonRound
  },

  props: {
    data: {
      type: Object,
      default: () => {return {}}
    },
    next: {
      type: Object,
      default: () => {return {}}
    },
    fullMode: {
      type: Boolean,
      default: false
    },
    isPath: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.panel
  border-radius 40px
  
  &__shitty-icon
    width 1em
    height 1em

  +mq($until: 'tablet')
    border-radius 40px 40px 0 0
  
  &__content
    max-height calc(100vh - 200px)
    
    &__title
      width 100%
      
      &.is-not-full
        width calc(100% - 80px)
  
  &__close
    position absolute
    top -45px
    left calc(50% - 45px)

    +mq($until: 'tablet')
      top -25px
      left calc(50% - 25px)
    
</style>