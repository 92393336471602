import { Component } from 'shimmer'
import gsap from 'gsap'

import { perpendicular } from '@/utils/Maths'

import {
  Vector3,
  CatmullRomCurve3,
  Line,
  LineBasicMaterial,
  BufferGeometry,
  Mesh,
  BoxGeometry,
  SphereGeometry,
  MeshBasicMaterial,
  MeshNormalMaterial,
  Raycaster,
  DoubleSide,
  Vector2
} from 'three'

import path from '@/assets/path'

import { webGL } from '@/webGL/WebGL'

import { VirtualScroll } from '@/utils/VirtualScroll'
import { emitter } from '@/utils/emitter'
import { positionPadding } from '@/utils/positionPadding'
const raycaster = new Raycaster()

const nbrStep = 2000
const margLook = .01
const maxLook = 1 - margLook
const halfPi = Math.PI * .5

const zoomDist = {
  min: .5,
  max: 3
}

const angle = {
  x: halfPi * .3,
  y: halfPi * .2
}

export class CameraPath extends Component {
  constructor ({pathName, isTouch}) {
    super()

    this.onScroll = this.onScroll.bind(this)
    this.onKeypoint = this.onKeypoint.bind(this)
    this.offKeypoint = this.offKeypoint.bind(this)
    this.computeLook = this.computeLook.bind(this)
    this.getPointAtProgess = this.getPointAtProgess.bind(this)

    this.scroll = 0
    this.isMobile = false
    this._isKeypoint = false
    this._mousePos = new Vector2(0, 0)
    this._accelerometer = new Vector2(0, 0)

    this.hitbox = new Mesh(new SphereGeometry(15, 5, 5), new MeshBasicMaterial({
      depthTest: false,
      depthWrite: false,
      side: DoubleSide
    }))
    this.hitbox.material.visible = false

    const positions = path[pathName].map(pos => { return new Vector3(pos[0], pos[2] + .3, -pos[1]) })

    this.curve = new CatmullRomCurve3(positions, false)

    this.virtualScroll = new VirtualScroll({
      clamp: {
        min: 0,
        max: this.curve.getLength() * 350
      },
      invert: isTouch
    })

    emitter.on('keypoint:on', this.onKeypoint)
    emitter.on('keypoint:off', this.offKeypoint)

    this.virtualScroll.onSmoothScroll(this.onScroll)
  }

  createLine () {
    const points = this.curve.getPoints(500)
    const curveHelper = new Line(
      new BufferGeometry().setFromPoints(points),
      new LineBasicMaterial({ color: 0x000000 })
    )

    webGL.scene.add(curveHelper)
  }

  // from path to keypoint
  onKeypoint (keypoint) {
    this.isKeypoint = true
    this.computeLook()
  }

  // from keypoint to path
  offKeypoint (keypoint) {
    const progress = keypoint.progress * .01
    const position = this.curve.getPoint(progress)
    const target = this.curve.getPoint(progress + margLook)

    gsap.to(webGL.camera.position, {
      x: position.x,
      y: position.y,
      z: position.z,
      duration: 2,
      ease: 'power2.inOut',
      onComplete: () => {
        this.scroll = progress
        this.virtualScroll.progress = progress
        this.isKeypoint = false
        emitter.emit('mousepos:reinit')
      }
    })

    gsap.to(webGL.camera.look, {
      x: target.x,
      y: target.y,
      z: target.z,
      duration: 2,
      ease: 'power2.inOut',
      onUpdate: () => {
        webGL.camera.forceUpdateLook = true
      }
    })
  }

  computePosition (dataPosition, dataLook, zoom = 80) {
    const dist = zoomDist.min + (100 - zoom) * (zoomDist.max - zoomDist.min) * .01

    return positionPadding(new Vector3(parseFloat(dataPosition.x), parseFloat(dataPosition.y), parseFloat(dataPosition.z)), new Vector3(parseFloat(dataLook.x), parseFloat(dataLook.y), parseFloat(dataLook.z)), dist, this.isMobile ? 0 : .25)
  }

  set debug (debug) {
    this._debug = debug
    if (debug) {
      this.obj = new Mesh(new BoxGeometry(.2, .2, .5), new MeshNormalMaterial())
      webGL.scene.add(this.obj)
      this.createLine()
    }
  }

  set mousePos (value) {
    this._mousePos = value
  }

  set accelerometer (value) {
    this._accelerometer = value
  }

  set isMobile (value) {
    this._isMobile = value
  }

  get isMobile () {
    return this._isMobile
  }

  set isKeypoint (value) {
    this._isKeypoint = value
    this.virtualScroll.isScrollEnable = !value
  }

  get isKeypoint () {
    return this._isKeypoint
  }

  set active (value) {
    this.virtualScroll.isScrollEnable = value
  }

  get active () {
    return this.virtualScroll.isScrollEnable
  }

  onUpdate () {
    if (this.isKeypoint) { return }

    if (this._debug) {
      const position = this.curve.getPoint(this.scroll)
      this.obj.position.copy(position)

      const target = this.curve.getPoint(this.scroll + margLook)
      this.obj.lookAt(target.x, target.y, target.z)
    }
    else {
      const position = this.curve.getPoint(this.scroll)
      webGL.camera.position.copy(position)

      const target = this.curve.getPoint(this.scroll + margLook)
      webGL.camera.lookAt(target.x, target.y, target.z)

      webGL.camera.rotateY(-this._mousePos.x * angle.x)
      webGL.camera.rotateX(this._mousePos.y * angle.y)

      webGL.camera.rotateY(-this._accelerometer.x * angle.x)
      webGL.camera.rotateX(this._accelerometer.y * angle.y)
    }
  }

  onScroll ({ progress }) {
    this.scroll = progress
  }

  computeLook () {
    this.hitbox.position.clone(webGL.camera.position)
    raycaster.setFromCamera(new Vector2(0, 0), webGL.camera)

    const intersects = raycaster.intersectObject( this.hitbox, false )

    if (intersects.length) {
      const look = new Vector3(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z)
      webGL.camera._lookAt.copy(look)
      webGL.camera._lastLookAt.copy(look)
    }
  }

  getPointAtProgess (progress) {
    return this.curve.getPoint(progress)
  }

  onDestroy () {
    this.virtualScroll.onDestroy()
    emitter.off('keypoint:on', this.onKeypoint)
    emitter.off('keypoint:off', this.offKeypoint)
    this.destroy()
  }
}