<template>
  <div class="transition u-wrapper-panel u-z-middle">
    <div
      v-for="index in 6"
      class="transition__circle"
      :class="'transition__circle--' + index"
    />
      <!-- :style="{transitionDelay: (.3 *  index) + 's'}" -->
  </div>
</template>

<script></script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.transition
  opacity 1
  transition opacity .5s 1.5s linear

  &__circle
    position absolute
    width 200vw
    height 200vw
    top calc(50% - 100vw)
    left calc(50% - 100vw)
    border-radius 100%
    transform scale(1, 1)
    will-change transform
    transition transform 1s ease-out-quad

    +mq($until: 'desktop')
      width 300vw
      height 300vw
      top calc(50% - 150vw)
      left calc(50% - 150vw)
    
    &:nth-child(even)
      background: $colors.white
    &:nth-child(odd)
      background: $colors.purple
    
    &--1
      transition-delay .3s
    &--2
      transition-delay .6s
    &--3
      transition-delay .9s
    
    &--4
      transform scale(0, 0)
    &--5
      transform scale(0, 0)
    &--6
      transform scale(0, 0)
      
  &.custom-enter-active &__circle
    transform scale(0, 0)

  &.custom-leave-active
    opacity 0

  &.custom-leave-active &__circle
    &--4
      transition-delay .3s
      transform scale(1, 1)
    &--5
      transition-delay .6s
      transform scale(1, 1)
    &--6
      transition-delay .9s
      transform scale(1, 1)

</style>