export const keypoint = `
  id
  title
  slug
  subtitle: sousTitre
  zoom
  position {
    x: col1
    y: col2
    z: col3
  }
  look {
    x: col1
    y: col2
    z: col3
  }
  image {
    id
    url
  }
  progress
  multipleTexts {
    ... on multipleTexts_multipleTexts_BlockType {
      id
      text
    }
  }
`

export const chapter = `
  id
  title
  slug
  progress
  multipleTexts {
    ... on multipleTexts_multipleTexts_BlockType {
      id
      text
    }
  }
`

export const levels = `
  id
  title
  subtitle: sousTitre
  slug
  image {
    id
    url
  }
`