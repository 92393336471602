// https://keisan.casio.com/exec/system/1359533867

export function cartesianToPolar (x, y, z = null){
  if (z === null) {
    return {
      radius: Math.sqrt(x * x + y * y),
      polar: Math.atan2(y, x)
    }
  }
  else {
    const radius = Math.sqrt(x * x + y * y + z * z)
    return {
      radius: radius,
      polar: Math.atan2(z,x),
      azimuth: Math.atan2(Math.sqrt(x * x + z * z), y)
    }
  }
}