class Perpendicular {
  clockwise (vector) {
    return {
      x: vector.y,
      y: -vector.x
    }
  }

  counterClockwise (vector) {
    return {
      x: -vector.y,
      y: vector.x
    }
  }
}

export const perpendicular = new Perpendicular()