<template>
  <div class="share u-row u-middle">
    <div class="share__items u-relative" @mouseleave="isHover = false">
      <div class="share__items__list">
        <transition-group :duration="2000" name="t-translate--fade-left">
          <app-button-round
            :isSmall="true"
            v-for="(social, index) in socials"
            v-if="isHover"
            :key="social.name"
            :href="social.url"
            @click="onLink(social.name)"
            class="u-marg-b-sm"
            :style="{transitionDelay: (.05 *  (socials.length - index)) + 's'}"
          >
            <svg class="u-full-width u-full-height u-fill-purple" preserveAspectRatio="xMidYMid meet">
              <use :xlink:href="'#' + social.icon"/>
            </svg>
          </app-button-round>
        </transition-group>
      </div>
      <app-button-round @click="isHover = !isHover" :isSmall="true">
        <svg class="u-full-width u-full-height u-fill-purple" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#share"/>
        </svg>
      </app-button-round>
    </div>
    <app-button @click="$emit('contact')" v-if="!$device.isMobile" class="u-marg-l-sm" contentClass="t-text" :isSmall="true" :isWhite="true" :icon="true">
      <svg class="level__contact__icon u-fill-purple u-marg-r-sm" preserveAspectRatio="xMidYMid meet">
        <use xlink:href="#discuss"/>
      </svg>
      Contact
    </app-button>
    <app-button-round v-else class="u-marg-l-sm">
      <svg class="u-full-width u-full-height u-fill-purple" preserveAspectRatio="xMidYMid meet">
        <use xlink:href="#discuss"/>
      </svg>
    </app-button-round>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import AppButtonRound from '@/components/common/AppButtonRound'

import CopyPaste from '@/utils/CopyPaste'

export default {
  components: {
    AppButtonRound,
    AppButton
  },

  data () {
    return {
      isHover: false,
      socials: [
        {
          name: 'Facebook',
          icon: 'facebook',
          url: 'https://www.facebook.com/sharer/sharer.php?u=https://metaverse.brig.ht/'
        },
        {
          name: 'Twitter',
          icon: 'twitter',
          url: `https://twitter.com/share?url=https://metaverse.brig.ht/&text=${encodeURIComponent('Le guide communication & Metaverse… Dans le Metaverse! Made by @brig_ht, 3D experiences.')}`
        },
        {
          name: 'Linkedin',
          icon: 'linkedin',
          url: 'https://linkedin.com/shareArticle/?url=https://metaverse.brig.ht/'
        },
        {
          name: 'Link',
          icon: 'link',
        }
      ]
    }
  },

  methods: {
    onHover () {
      this.isHover = !this.isHover
    },
    onLink (name) {
      if (name === 'Link') {
        CopyPaste.makeCopy({values: window.location.origin})
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.share
  position absolute
  bottom: $spacings.md
  left: $spacings.md
  
  &__items__list
    position absolute
    bottom 100%
    left 0

  &.isMobile
    position absolute
    top: $spacings.md
    right: 110px
</style>