<template>
  <div class="level-intro u-wrapper-panel u-row u-center" :class="big ? 'u-middle' : 'u-top u-marg-t-md u-pointer-none'">
    <div class="level-intro__wrapper u-pad-lg u-relative" :class="big ? 'u-pad-lg u-pad-md--sm' : 'is-small u-pad-x-md u-pad-y-sm'">
      <div class="level-intro__cartridge u-radius u-bg-yellow u-black t-h3" :class="big ? 't-h3' : 't-text'">
        ÉPISODE {{ index + 1 }}
      </div>
      <div class="u-relative u-middle" :class="{'u-row u-between': !big}">
        <div class="u-yellow" :class="big ? 't-h2' : 't-h5 u-w10of12 u-left'">
          {{ title }}
        </div>
        <div v-if="big" class="t-h3 t-medium u-black u-marg-t-md">
          {{ subTitle }}
        </div>
        <div v-else="!big" class="u-w2of12 u-box-1by1">
          <svg class="level-intro__svg-base u-wrapper-panel u-stroke-white" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#circle"/>
          </svg>
          <svg
            class="level-intro__svg-over u-wrapper-panel u-stroke-yellow"
            preserveAspectRatio="xMidYMid meet"
            :style="{'stroke-dashoffset': 227 - 227 * (seen / total)}"
          >
            <use xlink:href="#circle"/>
          </svg>
          <div class="u-wrapper-panel u-row u-center u-middle">
            <div class="t-h5 u-yellow">
              {{ seen }}/{{ total }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    big: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    subTitle: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    },
    seen: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.level-intro
  position absolute
  top 0
  left 0

  &__svg-base
    opacity .3

  &__svg-over
    stroke-dasharray 227
    transform rotate(-90deg)
  
  &__cartridge
    position absolute
    top 0
    left 50%
    transform translateX(-50%) translateY(-50%)
    padding .4em .8em

  &__wrapper
    width 50vw
    max-width 900px

    +mq($until: 'tablet')
      width calc(100vw - 60px)
    
    &.is-small
      width 550px

    &:before
      content ''
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      border-radius 40px
      background-color: $colors.purple
      opacity .8
</style>