import Vue from 'vue'
import { createStore } from 'vuex'

import global from '@/store/modules/global'
import data from '@/store/modules/data'

export const store = createStore({
  modules: {
    global,
    data
  }
})