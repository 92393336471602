<template>
  <div class="u-wrapper-panel u-row u-middle u-white">
    <img class="u-wrapper-panel u-fit-cover" src="/images/background.jpg">
    <div class="homepage__gradient u-wrapper-panel" />
    <div class="u-center u-relative u-full-width">
      <div>
        <div class="u-w1of12 u-w3of12--sm u-inline-block">
          <img class="u-block u-full-width" src="/images/bright__logo--white.png">
        </div>
      </div>
      <h1>
        <div class="t-h4 u-marg-t-lg">
          LE GUIDE POUR LE
        </div>
        <div class="homepage__border t-h1">
          METAVERSE
        </div>
        <div class="t-text">
          Pour la communication, les contenus, le marketing
        </div>
      </h1>
      <div class="u-row u-left u-middle u-marg-t-lg">
        <div v-if="!$device.isTablet" class="u-w3of12 u-marg-l-1of12">
          <div class="homepage__border t-h2">
            Hello Worlds !
          </div>
          <div class="t-text--small u-marg-t-sm">
            Bright vous propose de découvrir dans son guide immersif comment le Metaverse va changer la communication, les contenus, le marketing. Un guide en 3D, immersif, ludique… À l’image des contenus proposés par notre agence. Bon voyage !
          </div>
          <div class="t-text--small u-marg-t-sm u-row u-middle">
            <div>
              SCROLL TO EXPLORE
            </div>
            <div class="u-marg-l-xs">
              <svg class="homepage__arrow" preserveAspectRatio="xMidYMid meet">
                <use xlink:href="#weird-arrow-right"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="u-w7of12 u-w11of12--sm u-marg-l-1of12">
          <app-drag class="u-nowrap" :isScroll="!$device.isMobile">
            <div class="js-drag u-pad-r-md">
              <level-card
                :isVertical="$device.isTablet"
                class="homepage__card u-marg-r-sm | js-drag-item"
                v-for="level in levels"
                :data="level"
              />
            </div>
          </app-drag>
        </div>
      </div>
      <div v-if="firstLevel" class="u-center u-marg-t-lg">
        <app-button :to="{...$local('Level'), params: { level: firstLevel.slug }}" :isWhite="true">
          <span class="t-h5 u-black t-bold">
            {{ firstTime ? 'Commencer l’exploration' : 'Reprendre l’exploration' }}
          </span>
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import LevelCard from '@/components/common/LevelCard'
import AppButton from '@/components/common/AppButton'
import AppDrag from '@/components/common/AppDrag'

export default {
  components: {
    LevelCard,
    AppButton,
    AppDrag
  },

  computed: {
    levels () {
      return this.$store.getters['data/levels']
    },
    firstLevel () {
      return this.levels.find(level => {
        return !level.isSeen
      })
    },
    firstTime () {
      return !this.levels.filter(level => {
        return level.isSeen
      }).length
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/viewport-sizer'

.homepage
  &__gradient
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.63) -7.31%, rgba(0, 0, 0, 0) 76.38%)

  &__border
    color transparent
    -webkit-text-stroke: 2px $colors.white

  &__arrow
    width 3em
    height 1em
    display inline-block

  &__card
    viewportSizer('width', 480, 1920, 110px, 480px)

</style>