<template>
  <div class="end-screen u-wrapper-panel u-row u-middle u-center">
    <div class="u-w8of12 u-w10of12--sm u-relative u-row u-middle">
      <level-card :data="nextLevel" class="u-w6of12 u-w12of12--sm"/>
      <div class="u-w5of12 u-marg-l-1of12 u-w12of12--sm u-marg-l-0of12--sm u-left">
        <div class="t-h4 u-white" v-if="!$device.isMobile">
          Vous avez envie de<br/>collaborer avec nous ?
        </div>
        <div class="u-marg-t-md u-row u-middle u-center">
          <div class="u-w6of12 u-w8of12--sm u-pad-r-sm u-pad-r-0--sm">
            <app-button @click="$emit('contact')" :isFull="true" contentClass="t-text" :isSmall="true" :isWhite="true" :icon="true">
              <svg class="level__contact__icon u-fill-purple u-marg-r-sm" preserveAspectRatio="xMidYMid meet">
                <use xlink:href="#discuss"/>
              </svg>
              Contact
            </app-button>
          </div>
          <div class="u-w6of12 u-w8of12--sm u-pad-l-sm u-pad-l-0--sm u-marg-t-md--sm">
            <app-button @click="$emit('close')" :isFull="true" contentClass="t-text" :isSmall="true" :isGhost="true">
              Revoir l’épisode
            </app-button>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/AppButton'
import LevelCard from '@/components/common/LevelCard'

export default {
  components: {
    LevelCard,
    AppButton
  },

  props: {
    nextLevel: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.end-screen
  &:before
    content ''
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    opacity .8
    background-color $colors.black
</style>