

import { mapper, clamp } from '@/utils/Maths'

export default {
  props: {
    progress: {
      type: Number,
      default: 0
    },
    baseProgress: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      opacity: 0
    }
  },

  created () {
    this.opacityOut = {
      min: 0,
      max: 1
    }
  },

  methods: {
    computeOpacity () {
      if (this.baseProgress !== 0) {
        const delta = this.baseProgress - this.progress
        // if delta > 0, its before keypoint
        // starts to be visible at 0.03 before 
        // fully visible visible at 0.005 before
        this.opacity = delta > 0 ? mapper(delta, .07, .02, 0, 1) : mapper(delta, -.01, -.02, this.opacityOut.max, this.opacityOut.min)
        this.opacity = clamp(this.opacity, 0, 1)
      }
      else {
        this.opacity = 1
      }
    }
  }
}