<template>
  <div class="iframe__wrapper u-wrapper-panel u-row u-center u-middle">
    <div class="iframe u-w8of12 u-w10of12--sm u-box-16by9 u-box-9by16--sm u-bg-white">
      <iframe class="iframe__iframe u-wrapper-panel" allow="autoplay" :src="src" />
      <app-button-round @click="$emit('close')" class="iframe__close">
        <svg class="u-full-width u-full-height u-stroke-black" preserveAspectRatio="xMidYMid meet">
          <use xlink:href="#close"/>
        </svg>
      </app-button-round>
    </div>
  </div>
</template>

<script>
import AppButtonRound from '@/components/common/AppButtonRound'

export default {
  components: {
    AppButtonRound
  },

  props: {
    src: {
      type: String,
      default: 'https://brig.ht/contact?iframe=true'
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.iframe
  max-height 70%
  
  &__iframe
    border none
  
  &__wrapper
    &:before
      content ''
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      opacity .5
      background-color $colors.purple
  
  &__close
    position absolute
    top 0
    left 50%
    transform translate(-50%, -50%)
    
</style>