import {
  PerspectiveCamera as BasePerspectiveCamera,
  OrthographicCamera as BaseOrthographicCamera,
  Vector3
} from 'three'

export class PerspectiveCamera extends BasePerspectiveCamera {
  constructor () {
    super(...arguments)

    this.update = this.update.bind(this)

    this._lookAt = new Vector3(0, 0, 0)
    this._lastLookAt = new Vector3(0, 0, 0)
    this.forceUpdateLook = false
  }

  get look () {
    return this._lookAt
  }

  set look (target = new Vector3()) {
    this._lookAt.copy(target)
    this.lookAt(target.x, target.y, target.z)
    this._lastLookAt.copy(this._lookAt)
  }

  update () {
    if (this._lookAt.x !== this._lastLookAt.x || this._lookAt.y !== this._lastLookAt.y || this._lookAt.z !== this._lastLookAt.z || this.forceUpdateLook) {
      this.look = this._lookAt
    }

    this.forceUpdateLook = false
  }
}

export class OrthographicCamera extends BaseOrthographicCamera {
  constructor () {
    super(...arguments)

    this._lookAt = new Vector3(0, 0, 0)
  }

  get look () {
    return this._lookAt
  }

  set look (target = new Vector3()) {
    this._lookAt = target
    this.lookAt(target.x, target.y, target.z)
  }
}